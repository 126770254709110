import * as Gen from '../generated/i18n/ja';
import { MessageTable } from './Message';

export const ja: MessageTable = {
  ...Gen.Messages,

  headerTitle: 'Local Center',
  footerCopyright: 'Copyright ©Daihatsu & Perodua',
  signOut: 'Sign Out',

  toolbarGoToTop: 'Go to TOP page',
  toolbarPrintDashboard: 'Print Dash board',
  toolbarPrintSRO: 'Print SRO',
  toolbarSelectVin: 'VIN',
  toolbarSelectReg: 'Registration NO.',
  toolbarRefreshTitle: 'Refresh',

  signinPanelTitle: 'Login',
  signinPlaceholderUserId: 'ID',
  signinPlaceholderPassword: 'Password',
  signinButtonTitle: 'Login',

  homeSOSTitle: 'SOS',
  homeAirBagTitle: 'Air Bag Deployment',
  homeTheftAlertTitle: 'Theft Alert (DCM Removal, Immobilizer Alerm)',
  homeVehicleWarningTitle: 'Vehicle Warning',
  homeButtonTitle: `As of now {count} Units`,

  vehicleListTitle: '<Vehicle List>',
  vehicleListVin: 'VIN',
  vehicleListRegNo: 'Registration No.',
  vehicleListCustomerInfo: 'Customer Info.',
  vehicleListCustomerName: 'Name',
  vehicleListCustomerPhone: 'Phone',
  vehicleListCustomerIC: 'Customer IC',
  vehicleListModel: 'Model',
  vehicleListWarning: 'Warning Status',
  vehicleListWarningUndefined: '未定義({eventId})',
  vehicleListTime: 'Time Stamp',
  vehicleListPAA: 'PAA Stats',
  vehicleListPAAMember: `Member`,
  vehicleListPAANonMember: `Non-Member`,

  vehicleDetailBasicInfoTitle: '<Basic Information>',
  vehicleDetailVin: 'VIN :',
  vehicleDetailRegNo: 'Registration No. :',
  vehicleDetailRegYear: 'Registration Year :',
  vehicleDetailModel: 'Model :',
  vehicleDetailBodyColor: 'Body color :',
  vehicleDetailPAA: 'PAA status :',
  vehicleDetailName: 'Name : ',
  vehicleDetailCusId: 'Customer ID :',
  vehicleDetailPhone: 'Phone NO. :',
  vehicleDetailChipOwner: 'Owner',
  vehicleDetailChipDriver: 'Driver',
  vehicleDetailTitle: 'Vehicle Warning',
  vehicleDetailLocationTitle: '<Latest location>',
  vehicleDetailLocationAsOf: 'As of',
  vehicleDetailVehicleStatusTitle: '<Vehicle Status>',
  vehicleDetailVehicleStatusFuel: 'Fuel level :',
  vehicleDetailVehicleStatusRange: 'Driving Range :',
  vehicleDetailVehicleStatusDistance: 'Distance Traveled :',
  vehicleDetailVehicleStatusAve: 'Ave. Fuel Consumption :',
  vehicleDetailWarningLampTitle: '<Warning Lamp Status>',
  vehicleDetailWarningLampHeaderDate: 'date',
  vehicleDetailWarningLampHeaderTime: 'time',
  vehicleDetailWarningLampHeaderDetail: 'Detail',
  vehicleDetailDTCTitle: '<DTC/FFD List>',
  vehicleDetailDTCHeaderDate: 'date',
  vehicleDetailDTCHeaderECU: 'ECU',
  vehicleDetailDTCHeaderDTCID: 'DTC ID',
  vehicleDetailDTCHeaderDetail: 'Detail',
  vehicleDetailDTCHeaderFFD: 'FFD',
  vehicleDetailDTCNew: 'New',
  vehicleDetailEcuCodeUndefined: '未定義({ecuCode})',
  vehicleDetailDtcCodeUndefined: '未定義({ecuCode}_{dtcCode})',
};
