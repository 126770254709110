import WarningIcon from '@mui/icons-material/Warning';
import { Box } from '@mui/system';
import React, { Suspense } from 'react';
import { useAbnormalityInfoMap } from '../../backend/Api';
import { TextButton } from '../../components/Button';
import { IntlText } from '../../components/Intl';
import { Layout } from '../../components/Layout';
import { Loading } from '../../components/Loading';
import { useIntl } from '../../i18n/Intl';
import { defaultLocale } from '../../i18n/Locale';
import { MessageId, MessageKeys } from '../../i18n/Message';
import { useNavigate } from '../../route/Navigate';
import { Style } from '../../style/Style';
import { Theme } from '../../theme/Theme';
import { VehicleListState } from '../vehicle-list/VehicleList';

const styles = Style({
  container: {
    padding: `24px`,
  },
  itemContainer: {
    padding: `8px`,
  },
  itemTitleContainer: {
    padding: `4px`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemWarn: {
    fontSize: `2rem`,
    color: Theme.home.warn.color,
  },
  itemWarnHide: {
    fontSize: `2rem`,
    color: Theme.home.warn.color,
    opacity: 0,
  },
  itemTitle: {
    fontSize: `1.4rem`,
    fontWeight: `800`,
  },
  buttonContainer: {
    paddingLeft: `4em`,
  },
  button: {
    minWidth: `20em`,
    padding: `0.8em`,
    borderRadius: `0.4em`,
    backgroundColor: Theme.home.button.backgroundColor,
  },
  buttonText: {
    fontSize: `1.2rem`,
  },
});

function Item({
  titleId,
  count,
  onClick,
}: Readonly<{
  titleId: MessageKeys;
  count: number;
  onClick?: () => void;
}>): React.ReactElement {
  const intl = useIntl(defaultLocale);
  return (
    <Box sx={styles.itemContainer}>
      <Box sx={styles.itemTitleContainer}>
        <WarningIcon sx={count > 0 ? styles.itemWarn : styles.itemWarnHide} />
        <IntlText id={titleId} style={styles.itemTitle} />
      </Box>
      <Box sx={styles.buttonContainer}>
        <TextButton
          title={intl.formatMessageAsString(
            { id: MessageId.homeButtonTitle as MessageKeys },
            { count },
          )}
          sx={styles.button}
          textSx={styles.buttonText}
          onClick={onClick}
        />
      </Box>
    </Box>
  );
}

function Body(): React.ReactElement | null {
  const navigate = useNavigate<VehicleListState>();
  return useAbnormalityInfoMap().unwrap(
    map => (
      <Box sx={styles.container}>
        <Item
          titleId={MessageId.homeSOSTitle}
          count={map.sos.vinCount}
          onClick={() =>
            navigate('/vehicle-list', {
              state: { eventClassId: map.sos.eventClassId },
            })
          }
        />
        <Item
          titleId={MessageId.homeAirBagTitle}
          count={map.airBag.vinCount}
          onClick={() =>
            navigate('/vehicle-list', {
              state: { eventClassId: map.airBag.eventClassId },
            })
          }
        />
        <Item
          titleId={MessageId.homeTheftAlertTitle}
          count={map.theftAlert.vinCount}
          onClick={() =>
            navigate('/vehicle-list', {
              state: { eventClassId: map.theftAlert.eventClassId },
            })
          }
        />
        <Item
          titleId={MessageId.homeVehicleWarningTitle}
          count={map.vehicleWarning.vinCount}
          onClick={() =>
            navigate('/vehicle-list', {
              state: { eventClassId: map.vehicleWarning.eventClassId },
            })
          }
        />
      </Box>
    ),
    () => <Box>No data from the backend</Box>,
  );
}

export function Home(): React.ReactElement {
  return (
    <Layout showSelectBox showSearchBox>
      <Suspense fallback={<Loading />}>
        <Body />
      </Suspense>
    </Layout>
  );
}
