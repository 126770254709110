import {
  Either,
  Left,
  None,
  Option,
  Right,
  Some,
  identity,
} from '@auto/dango-util';
import { MemberInfo } from '@auto/monaka-client/dist/dashboard';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import * as MUI from '@mui/material';
import { Box } from '@mui/system';
import React, { Suspense } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../app/AppContext';
import { VehicleListSort } from '../../app/AppState';
import { VehicleProfileEvent, useVehicleProfileList } from '../../backend/Api';
import { Chip } from '../../components/Chip';
import { IntlText } from '../../components/Intl';
import { Layout } from '../../components/Layout';
import { Loading } from '../../components/Loading';
import { EventId } from '../../generated/i18n/Types';
import { useIntl } from '../../i18n/Intl';
import { defaultLocale } from '../../i18n/Locale';
import { MessageId, MessageKeys } from '../../i18n/Message';
import { useLocation } from '../../route/Location';
import { MUIStyle, Style } from '../../style/Style';
import { Theme } from '../../theme/Theme';
import { getEventTitleIcon } from '../../utils/EventTitleIcon';

export type VehicleListState = Readonly<{
  eventClassId: number;
}>;

export type VehicleListHeaderKeys =
  | 'vin'
  | 'regNo'
  | 'customerInfo'
  | 'customerName'
  | 'customerPhone'
  | 'customerIc'
  | 'model'
  | 'warning'
  | 'time'
  | 'paa';

export type VehicleListHeaders = Readonly<{
  [key in VehicleListHeaderKeys]: [MUIStyle, MessageKeys];
}>;

export type SortDirection = 'Ascend' | 'Descend';
export type Sortable = 'None' | Either<SortDirection, SortDirection>;

const styles = Style({
  container: {
    flexGrow: 1,
    flexShrink: 1,
    maxWidth: `100%`,
    maxHeight: `100%`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  titleBar: {
    padding: `16px 24px 16px 24px`,
    backgroundColor: Theme.vehicleList.titleBar.beckgroundColor,
  },
  titleBarText: {
    fontSize: `1.5rem`,
    fontWeight: `800`,
  },
  body: {
    flex: 1,
    padding: `16px`,
    maxWidth: `100%`,
    maxHeight: `100%`,
    overflow: 'auto',
  },
  bodyTitle: {
    paddingTop: `8px`,
    paddingBottom: `8px`,
  },
  table: {
    display: 'grid',
    gridGap: `0px`,
    borderTop: `1px solid ${Theme.vehicleList.table.border}`,
    borderLeft: `1px solid ${Theme.vehicleList.table.border}`,
    overflowX: 'auto',
    '& .header-item': {
      padding: `4px`,
      wordWrap: 'break-word',
      wordBreak: 'break-all',
      borderBottom: `1px solid ${Theme.vehicleList.table.border}`,
      borderRight: `1px solid ${Theme.vehicleList.table.border}`,
      backgroundColor: Theme.vehicleList.table.header.backgroundColor,
      color: Theme.vehicleList.table.header.color,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .data-item': {
      padding: `4px`,
      wordWrap: 'break-word',
      wordBreak: 'break-all',
      borderBottom: `1px solid ${Theme.vehicleList.table.border}`,
      borderRight: `1px solid ${Theme.vehicleList.table.border}`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  tableHeaderVin: {
    gridRow: `1 / 3`,
    gridColumn: `1`,
  },
  tableHeaderRegNo: {
    gridRow: `1 / 3`,
    gridColumn: `2`,
  },
  tableHeaderCusInfo: {
    gridRow: `1`,
    gridColumn: `3 / 6`,
  },
  tableHeaderCusName: {
    gridRow: `2`,
    gridColumn: `3`,
  },
  tableHeaderCusPhone: {
    gridRow: `2`,
    gridColumn: `4`,
  },
  tableHeaderCusIC: {
    gridRow: `2`,
    gridColumn: `5`,
  },
  tableHeaderModel: {
    gridRow: `1 / 3`,
    gridColumn: `6`,
  },
  tableHeaderWarn: {
    gridRow: `1 / 3`,
    gridColumn: `7`,
  },
  tableHeaderTime: {
    gridRow: `1 / 3`,
    gridColumn: `8`,
  },
  tableHeaderPAA: {
    gridRow: `1 / 3`,
    gridColumn: `9`,
  },
  cellName: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },

  chipOwner: {
    fontSize: '0.8em',
    backgroundColor: Theme.vehicleList.table.row.chipOwner.backgroundColor,
    color: Theme.vehicleList.table.row.chipOwner.fontColor,
    '& .MuiChip-label': {
      padding: '2px',
    },
  },
  chipDriver: {
    fontSize: '0.8em',
    backgroundColor: Theme.vehicleList.table.row.chipDriver.backgroundColor,
    color: Theme.vehicleList.table.row.chipDriver.fontColor,
    '& .MuiChip-label': {
      padding: '2px',
    },
  },

  itemWarning: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  sortButtonOn: {
    color: Theme.vehicleList.table.header.color,
  },
  sortButtonOff: {
    color: Theme.vehicleList.table.header.color,
    opacity: Theme.vehicleList.table.header.sort.disabled,
  },
  sortRightPadding: {
    width: '8px',
  },
});

const headers: VehicleListHeaders = {
  vin: [styles.tableHeaderVin, MessageId.vehicleListVin],
  regNo: [styles.tableHeaderRegNo, MessageId.vehicleListRegNo],
  customerInfo: [styles.tableHeaderCusInfo, MessageId.vehicleListCustomerInfo],
  customerName: [styles.tableHeaderCusName, MessageId.vehicleListCustomerName],
  customerPhone: [
    styles.tableHeaderCusPhone,
    MessageId.vehicleListCustomerPhone,
  ],
  customerIc: [styles.tableHeaderCusIC, MessageId.vehicleListCustomerIC],
  model: [styles.tableHeaderModel, MessageId.vehicleListModel],
  warning: [styles.tableHeaderWarn, MessageId.vehicleListWarning],
  time: [styles.tableHeaderTime, MessageId.vehicleListTime],
  paa: [styles.tableHeaderPAA, MessageId.vehicleListPAA],
};

function cmpStr(a: string, b: string): number {
  return a > b ? 1 : -1;
}

function getPrimaryMemberInfo(a: VehicleProfileEvent): Option<MemberInfo> {
  if (a.memberInfoList.length === 0) return None();
  else if (a.memberInfoList.length === 1) return Some(a.memberInfoList[0]);
  else return Option(a.memberInfoList.find(m => m.ownerStatus === 'owner'));
}

function compareSortKey(
  key: VehicleListHeaderKeys,
  a: VehicleProfileEvent,
  b: VehicleProfileEvent,
): number {
  switch (key) {
    case 'vin':
      return cmpStr(a.vin, b.vin);
    case 'regNo':
      return cmpStr(a.vehicleRegNo, b.vehicleRegNo);
    case 'customerInfo':
    case 'customerName':
      return Option.join(
        getPrimaryMemberInfo(a),
        getPrimaryMemberInfo(b),
      ).unwrap(
        ([x, y]) => cmpStr(x.fullName, y.fullName),
        () => 0,
      );
    case 'customerPhone':
      return Option.join(
        getPrimaryMemberInfo(a),
        getPrimaryMemberInfo(b),
      ).unwrap(
        ([x, y]) => cmpStr(x.phoneMobile, y.phoneMobile),
        () => 0,
      );
    case 'customerIc':
      return Option.join(
        getPrimaryMemberInfo(a),
        getPrimaryMemberInfo(b),
      ).unwrap(
        ([x, y]) => cmpStr(x.icNo, y.icNo),
        () => 0,
      );
    case 'model':
      return cmpStr(a.model, b.model);
    case 'warning':
      return cmpStr(a.eventId, b.eventId);
    case 'time':
      return a.timestamp.getTime() - b.timestamp.getTime();
    case 'paa':
      return cmpStr(a.paaStatus, a.paaStatus);
  }
}

function sortVehicleProfileEvents(
  data: VehicleProfileEvent[],
  sort: VehicleListSort,
): VehicleProfileEvent[] {
  return Object.entries(sort)
    .reduce<Option<[VehicleListHeaderKeys, SortDirection]>>(
      (r, [key, sortable]) =>
        r.isSome() || sortable === 'None'
          ? r
          : sortable.unwrap(
              direction => Some([key as VehicleListHeaderKeys, direction]),
              () => r,
            ),
      None(),
    )
    .unwrap(
      ([key, direction]) =>
        data.sort(
          (a, b) =>
            compareSortKey(key, a, b) * (direction === 'Ascend' ? 1 : -1),
        ),
      () => data,
    );
}

export function SortOn(
  direction: SortDirection,
): Either<SortDirection, SortDirection> {
  return Right(direction);
}

export function SortOff(
  direction: SortDirection,
): Either<SortDirection, SortDirection> {
  return Left(direction);
}

function inverseDirection(direction: SortDirection): SortDirection {
  if (direction === 'Ascend') return 'Descend';
  else return 'Ascend';
}

function onClickSort(
  appContext: AppContext,
  key: VehicleListHeaderKeys,
  sortKey: VehicleListHeaderKeys,
  direction: SortDirection,
): void {
  appContext.dispatch(data => ({
    ...data,
    vehicleList: {
      ...data.vehicleList,
      sort: {
        ...data.vehicleList.sort,
        ...(Object.fromEntries(
          [[key, SortOn(inverseDirection(direction))]].concat(
            key === sortKey ? [] : [[sortKey, SortOff(direction)]],
          ),
        ) as VehicleListSort),
      },
    },
  }));
}

function SortButton({
  targetKey,
  sortKey,
  messageId,
}: Readonly<{
  targetKey: VehicleListHeaderKeys;
  sortKey: VehicleListHeaderKeys;
  messageId: MessageKeys;
}>): React.ReactElement {
  const appContext = React.useContext(AppContext);
  const sortable = appContext.state.vehicleList.sort[targetKey];
  if (sortable == 'None') return <IntlText id={messageId} />;
  else {
    const direction = sortable.unwrap(identity, identity);
    return (
      <>
        <MUI.IconButton
          aria-label='sort'
          sx={
            targetKey === sortKey ? styles.sortButtonOn : styles.sortButtonOff
          }
          onClick={() => onClickSort(appContext, targetKey, sortKey, direction)}
        >
          {direction === 'Ascend' ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
        </MUI.IconButton>
        <IntlText id={messageId} />
        <Box sx={styles.sortRightPadding} />
      </>
    );
  }
}

function TableHeader(): React.ReactElement {
  const appContext = React.useContext(AppContext);
  const sortKey = Option(
    Object.entries(appContext.state.vehicleList.sort).find(
      ([_, v]) => v !== 'None' && v.isRight(),
    ),
  ).unwrap(
    ([k, _]) => k,
    () => 'vin',
  ) as VehicleListHeaderKeys;

  return (
    <>
      {Object.entries(headers).map(([key, [style, messageId]]) => (
        <Box key={key} className='header-item' sx={style}>
          <SortButton
            targetKey={key as VehicleListHeaderKeys}
            sortKey={sortKey}
            messageId={messageId}
          />
        </Box>
      ))}
    </>
  );
}

function TableBody({
  data,
}: Readonly<{ data: VehicleProfileEvent[] }>): React.ReactElement {
  type Rows = {
    row: number;
    elements: React.ReactNode[];
  };
  const appContext = React.useContext(AppContext);
  return (
    <>
      {
        sortVehicleProfileEvents(
          data,
          appContext.state.vehicleList.sort,
        ).reduce(
          ({ row, elements }, evt) => ({
            row: row + evt.memberInfoList.length,
            elements: elements.concat([
              <EventRow
                key={`row-${row}`}
                row={row}
                vehicleProfileEvent={evt}
              />,
            ]),
          }),
          { row: 3, elements: [] } as Rows,
        ).elements
      }
    </>
  );
}

function EventRow({
  row,
  vehicleProfileEvent,
}: Readonly<{
  row: number;
  vehicleProfileEvent: VehicleProfileEvent;
}>): React.ReactElement {
  const intl = useIntl(defaultLocale);
  const evt = vehicleProfileEvent;
  const len = evt.memberInfoList.length;
  const titleIcon = getEventTitleIcon(evt.eventId as EventId, intl);
  const timestamp = new Date(evt.timestamp);
  const date = intl.formatDate(timestamp);
  const time = intl.formatTime(timestamp);
  const items = {
    vin: evt.vin,
    regNo: evt.vehicleRegNo,
    name: evt.memberInfoList.map(m => [
      m.ownerStatus === 'owner'
        ? MessageId.vehicleDetailChipOwner
        : MessageId.vehicleDetailChipDriver,
      m.ownerStatus === 'owner' ? styles.chipOwner : styles.chipDriver,
      m.fullName,
    ]),
    phone: evt.memberInfoList.map(m => m.phoneMobile),
    icNo: evt.memberInfoList.map(m => m.icNo),
    model: evt.model,
    warning: titleIcon,
    time: `${date} ${time}`,
    paa:
      evt.paaStatus === 'subscribed'
        ? intl.formatMessageAsString({ id: MessageId.vehicleListPAAMember })
        : intl.formatMessageAsString({ id: MessageId.vehicleListPAANonMember }),
  };

  const Item = Box;

  return (
    <>
      {Object.entries(items).map(([key, item], col) =>
        item instanceof Array ? (
          item.map((m, r) => (
            <Item
              className='data-item'
              key={`item-${row + r}-${col}`}
              sx={{
                gridRow: `${row + r}`,
                gridColumn: col + 1,
              }}
            >
              {m instanceof Array ? (
                <Box sx={styles.cellName}>
                  <Chip label={m[0] as MessageKeys} sx={m[1] as MUIStyle} />
                  <Box>{m[2]}</Box>
                </Box>
              ) : (
                m
              )}
            </Item>
          ))
        ) : (
          <Item
            key={`item-${row}-${col}`}
            className='data-item'
            sx={{
              gridRow: `${row}/span ${len}`,
              gridColumn: col + 1,
            }}
          >
            {col === 0 ? (
              <Link
                to='/vehicle-detail'
                state={{
                  type: 'VehicleProfile',
                  value: vehicleProfileEvent,
                }}
              >
                {item}
              </Link>
            ) : key === 'warning' ? (
              <Box sx={styles.itemWarning}>
                {Option.join(titleIcon.title, titleIcon.icon).unwrap(
                  ([title, icon]) => (
                    <>
                      <img src={icon} />
                      {title}
                    </>
                  ),
                  () => (
                    <>
                      {intl.formatMessageAsString(
                        { id: 'vehicleListWarningUndefined' },
                        { eventId: evt.eventId },
                      )}
                    </>
                  ),
                )}
              </Box>
            ) : (
              item
            )}
          </Item>
        ),
      )}
    </>
  );
}

function Body({
  eventClassId,
}: Readonly<{
  eventClassId: number;
}>): React.ReactElement | null {
  const appContext = React.useContext(AppContext);
  const data = useVehicleProfileList({ eventClassId });

  const Container = Box;
  const TitleBar = Box;
  const Body = Box;
  const BodyTitleBox = Box;
  const TableBox = Box;
  const Table = Box;

  return (
    <Container sx={styles.container}>
      <TitleBar sx={styles.titleBar}>
        <IntlText
          id={appContext.state.vehicleList.title}
          style={styles.titleBarText}
        />
      </TitleBar>
      <Body sx={styles.body}>
        <BodyTitleBox sx={styles.bodyTitle}>
          <IntlText id='vehicleListTitle' />
        </BodyTitleBox>
        <TableBox>
          <Table sx={styles.table}>
            <TableHeader />
            <TableBody data={data} />
          </Table>
        </TableBox>
      </Body>
    </Container>
  );
}

export function VehicleList(): React.ReactElement {
  const { state } = useLocation<VehicleListState>();
  return state.unwrap(
    ({ eventClassId }) => (
      <Layout showGoToTop showSelectBox showSearchBox>
        <Suspense fallback={<Loading />}>
          <Body eventClassId={eventClassId} />
        </Suspense>
      </Layout>
    ),
    () => <Box>eventClassId is not specified</Box>,
  );
}
