import { Option } from '@auto/dango-util';
import { Box } from '@mui/system';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import React from 'react';
import { IntlDateTime, IntlText } from '../../components/Intl';
import { useIntl } from '../../i18n/Intl';
import { defaultLocale } from '../../i18n/Locale';
import { MessageId } from '../../i18n/Message';
import * as Icons from '../../icons/Icons';
import { Style } from '../../style/Style';
import { Theme } from '../../theme/Theme';

const styles = Style({
  mapContainer: {
    width: `320px`,
    height: `200px`,
  },
  asof: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  latlng: {
    fontSize: '0.7em',
    display: 'flex',
    flexDirection: 'row',
  },
  space: {
    width: `0.5em`,
  },
  noLocation: {
    display: 'flex',
    width: `100%`,
    height: `100%`,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Theme.vehicleDetail.map.noLocation.backgroundColor,
    color: Theme.vehicleDetail.map.noLocation.color,
    border: `solid 1px ${Theme.vehicleDetail.map.noLocation.border}`,
    borderRadius: `8px`,
  },
});

function LocationMap({
  lat,
  lng,
}: Readonly<{
  lat: Option<number>;
  lng: Option<number>;
}>): React.ReactElement {
  const intl = useIntl(defaultLocale);

  return Option.join(lat, lng).unwrap(
    ([lat, lng]) => (
      <LoadScript googleMapsApiKey='AIzaSyBybg4inPFTpFKgXYC1SlTFZNUI5AhE7dQ'>
        <GoogleMap
          mapContainerStyle={{ width: `100%`, height: `100%` }}
          center={{
            lat,
            lng,
          }}
          zoom={11}
          options={{
            disableDefaultUI: true,
            clickableIcons: false,
          }}
        >
          <Marker
            position={{
              lat,
              lng,
            }}
            icon={Icons.ic_location_vehicle}
          />
        </GoogleMap>
      </LoadScript>
    ),
    () => (
      <Box sx={styles.noLocation}>
        {intl.formatMessageAsString({
          id: 'VehicleAlert_vehicleLocationErrorMsg',
        })}
      </Box>
    ),
  );
}

export function VehicleLocation({
  lat,
  lng,
  asof,
}: Readonly<{
  lat: Option<number>;
  lng: Option<number>;
  asof: Date;
}>): React.ReactElement {
  return (
    <Box>
      <IntlText id={MessageId.vehicleDetailLocationTitle} />
      <Box sx={styles.mapContainer}>
        <LocationMap lat={lat} lng={lng} />
      </Box>
      <Box sx={styles.asof}>
        <IntlText id={MessageId.vehicleDetailLocationAsOf} />
        <Box sx={styles.space} />
        <IntlDateTime date={asof} />
        <Box sx={styles.space} />
        <Box sx={styles.latlng}>
          {lat.map(_ => `${_}`).getOrElse(() => '')}
          <Box sx={styles.space} />
          {lng.map(_ => `${_}`).getOrElse(() => '')}
        </Box>
      </Box>
    </Box>
  );
}
