import { Box, SystemStyleObject, Theme } from '@mui/system';
import { PrimitiveType } from 'intl-messageformat';
import React from 'react';
import { FormatDateOptions } from 'react-intl';
import { useIntl } from '../i18n/Intl';
import { defaultLocale } from '../i18n/Locale';
import { MessageKeys } from '../i18n/Message';

export function IntlText({
  id,
  values,
  style,
}: Readonly<{
  id: MessageKeys;
  values?: Record<string, PrimitiveType>;
  style?: SystemStyleObject<Theme>;
}>): React.ReactElement {
  const intl = useIntl(defaultLocale);
  return <Box sx={style}>{intl.formatMessageAsNode({ id }, values)}</Box>;
}

export function IntlDateTime({
  date,
  options,
  style,
}: Readonly<{
  date: Date;
  options?: {
    date?: FormatDateOptions;
    time?: FormatDateOptions;
  };
  style?: SystemStyleObject<Theme>;
}>): React.ReactElement {
  const intl = useIntl(defaultLocale);
  return (
    <Box sx={style}>
      {intl.formatDate(date, options?.date)}{' '}
      {intl.formatTime(date, options?.time)}
    </Box>
  );
}
