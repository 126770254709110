import { Option } from '@auto/dango-util';
import { VehicleProfile } from '@auto/monaka-client/dist/dashboard';
import WarningIcon from '@mui/icons-material/Warning';
import { Box } from '@mui/system';
import React, { Suspense } from 'react';
import {
  VehicleDetail as VehicleDetailData,
  useMemberVehicleProfile,
  useVehicleDetail,
} from '../../backend/Api';
import { IntlText } from '../../components/Intl';
import { Layout } from '../../components/Layout';
import { Loading } from '../../components/Loading';
import { MessageId } from '../../i18n/Message';
import { useLocation } from '../../route/Location';
import { Style } from '../../style/Style';
import { Theme } from '../../theme/Theme';
import { DTCList } from './DTCList';
import { SideBar } from './SideBar';
import { VehicleLocation } from './VehicleLocation';
import { VehicleStatus } from './VehicleStatus';
import { WarningLamp } from './WarningLamp';

export type VehicleDetailState = Readonly<
  | {
      type: 'VehicleProfile';
      value: VehicleProfile;
    }
  | {
      type: 'Vin';
      value: string;
    }
  | {
      type: 'RegNo';
      value: string;
    }
>;

const styles = Style({
  container: {
    maxWidth: `100%`,
    maxHeight: `100%`,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    padding: `8px`,
    fontSize: `1.5rem`,
    fontWeight: `800`,
    borderTop: `1px solid ${Theme.vehicleDetail.title.border}`,
    borderBottom: `1px solid ${Theme.vehicleDetail.title.border}`,
    backgroundColor: Theme.vehicleDetail.title.backgroundColor,
    color: Theme.vehicleDetail.title.fontColor,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  body: {
    padding: `8px`,
    maxWidth: `100%`,
    maxHeight: `100%`,
    overflow: 'auto',
  },
  locationStatus: {
    display: 'flex',
    flexDirection: 'row',
  },
  mapContainer: {
    width: `320px`,
    height: `250px`,
  },
  hgap: {
    width: `1em`,
  },
  vgap: {
    height: `1em`,
  },
});

function Body({
  vehicleDetail,
}: Readonly<{
  vehicleDetail: VehicleDetailData;
}>): React.ReactElement {
  const Body = Box;
  const LocationStatus = Box;

  return (
    <Body sx={styles.body}>
      <LocationStatus sx={styles.locationStatus}>
        <VehicleLocation
          lat={Option(vehicleDetail.serviceTelemetry.latitude)}
          lng={Option(vehicleDetail.serviceTelemetry.longitude)}
          asof={vehicleDetail.serviceTelemetry.timestamp}
        />
        <Box sx={styles.hgap} />
        <VehicleStatus serviceTelemetry={vehicleDetail.serviceTelemetry} />
      </LocationStatus>
      <Box sx={styles.vgap} />
      <WarningLamp vehicleEvents={vehicleDetail.eventTelemetry} />
      <Box sx={styles.vgap} />
      <DTCList dtcDetailList={vehicleDetail.dtcDetailList} />
    </Body>
  );
}

function VehicleProfileProvider({
  state,
  onSuccess,
}: Readonly<{
  state: VehicleDetailState;
  onSuccess: (vehicleProfile: VehicleProfile) => React.ReactElement;
}>): React.ReactElement {
  return useMemberVehicleProfile({ state }).unwrap(onSuccess, () => (
    <Box>Failed to get VehicleProfile</Box>
  ));
}

function VehicleDetailProvider({
  vehicleProfile,
  onSuccess,
}: Readonly<{
  vehicleProfile: VehicleProfile;
  onSuccess: (vehicleDetail: VehicleDetailData) => React.ReactElement;
}>): React.ReactElement {
  return useVehicleDetail({ vin: vehicleProfile.vin }).unwrap(onSuccess, () => (
    <Box>No data from the backend</Box>
  ));
}

export function VehicleDetail(): React.ReactElement {
  const { state } = useLocation<VehicleDetailState>();
  const Container = Box;
  const Title = Box;
  return state.unwrap(
    state => (
      <Suspense fallback={<Loading />}>
        <VehicleProfileProvider
          state={state}
          onSuccess={vehicleProfile => (
            <VehicleDetailProvider
              vehicleProfile={vehicleProfile}
              onSuccess={vehicleDetail => (
                <Layout
                  showGoToTop
                  showPrintDashboard
                  showPrintSRO
                  sideBar={<SideBar vehicleProfile={vehicleProfile} />}
                  vehicleProfile={vehicleProfile}
                  vehicleDetail={vehicleDetail}
                >
                  <Container sx={styles.container}>
                    <Title sx={styles.title}>
                      <WarningIcon />
                      <IntlText id={MessageId.vehicleDetailTitle} />
                    </Title>
                    <Body vehicleDetail={vehicleDetail} />
                  </Container>
                </Layout>
              )}
            />
          )}
        />
      </Suspense>
    ),
    () => <Box>vehicleProfileEvent is not specified</Box>,
  );
}
