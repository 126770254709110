import { Scope } from '@auto/dango-util';
import { BuildOpt } from './BuildOpt';

export type Config = Readonly<{
  auth: Readonly<{
    url: string;
    client_id: string;
    client_secret: string;
    scope: string;
    response_type: string;
    redirect_uri: string;
    logout_uri: string;
    state: string;
    grant_type: string;
  }>;
  client: Readonly<{
    url: string;
    region: string;
    locale: 'jp' | 'my';
  }>;
}>;

export const Config: Config = Scope(() => {
  switch (BuildOpt.env) {
    case 'debug':
      return {
        auth: {
          url: 'https://dashboard-dxi-dev-jp.auth.ap-northeast-1.amazoncognito.com',
          client_id: '7tdoalcfdh5gu4oj2o6qvdaeku',
          client_secret: '',
          scope: 'openid',
          response_type: 'code',
          redirect_uri: 'http://localhost:3000/auth-code',
          logout_uri: 'http://localhost:3000/sign-out',
          state: 'STATE',
          grant_type: 'authorization_code',
        },
        client: {
          url: 'https://pvuh7mfe6fft7bt55w2amneqnq.appsync-api.ap-northeast-1.amazonaws.com/graphql',
          region: 'ap-northeast-1',
          locale: 'jp',
        },
      };

    case 'local-devjp':
      return {
        auth: {
          url: 'https://dashboard-dxi-dev-jp.auth.ap-northeast-1.amazoncognito.com',
          client_id: '7tdoalcfdh5gu4oj2o6qvdaeku',
          client_secret: '',
          scope: 'openid',
          response_type: 'code',
          redirect_uri: 'http://localhost:3000/auth-code',
          logout_uri: 'http://localhost:3000/sign-out',
          state: 'STATE',
          grant_type: 'authorization_code',
        },
        client: {
          url: 'https://pvuh7mfe6fft7bt55w2amneqnq.appsync-api.ap-northeast-1.amazonaws.com/graphql',
          region: 'ap-northeast-1',
          locale: 'jp',
        },
      };

    case 'devjp':
      return {
        auth: {
          url: 'https://dashboard-dxi-dev-jp.auth.ap-northeast-1.amazoncognito.com',
          client_id: '7tdoalcfdh5gu4oj2o6qvdaeku',
          client_secret: '',
          scope: 'openid',
          response_type: 'code',
          redirect_uri:
            'https://frontend.dashboard.dev-jp.dmc-dxi.com/auth-code',
          logout_uri: 'https://frontend.dashboard.dev-jp.dmc-dxi.com/sign-out',
          state: 'STATE',
          grant_type: 'authorization_code',
        },
        client: {
          url: 'https://pvuh7mfe6fft7bt55w2amneqnq.appsync-api.ap-northeast-1.amazonaws.com/graphql',
          region: 'ap-northeast-1',
          locale: 'jp',
        },
      };

    case 'local-devmy':
      return {
        auth: {
          url: 'https://dashboard-dxi-dev-my.auth.ap-southeast-1.amazoncognito.com',
          client_id: '39ln3orpegg46dhe4nc3jl5bgj',
          client_secret: '',
          scope: 'openid',
          response_type: 'code',
          redirect_uri: 'http://localhost:3000/auth-code',
          logout_uri: 'http://localhost:3000/sign-out',
          state: 'STATE',
          grant_type: 'authorization_code',
        },
        client: {
          url: 'https://gytqvdq5ebeiln7ugf22yoalpm.appsync-api.ap-southeast-1.amazonaws.com/graphql',
          region: 'ap-southeast-1',
          locale: 'my',
        },
      };

    case 'devmy':
      return {
        auth: {
          url: 'https://dashboard-dxi-dev-my.auth.ap-southeast-1.amazoncognito.com',
          client_id: '39ln3orpegg46dhe4nc3jl5bgj',
          client_secret: '',
          scope: 'openid',
          response_type: 'code',
          redirect_uri:
            'https://frontend.dashboard.dev-my.dmc-dxi.com/auth-code',
          logout_uri: 'https://frontend.dashboard.dev-my.dmc-dxi.com/sign-out',
          state: 'STATE',
          grant_type: 'authorization_code',
        },
        client: {
          url: 'https://gytqvdq5ebeiln7ugf22yoalpm.appsync-api.ap-southeast-1.amazonaws.com/graphql',
          region: 'ap-southeast-1',
          locale: 'my',
        },
      };

    case 'local-qamy':
      return {
        auth: {
          url: 'https://dashboard-dxi-qa-poc-my.auth.ap-southeast-1.amazoncognito.com',
          client_id: '1h8kkl0cfso5mrqcg1qvn7e4vp',
          client_secret: '',
          scope: 'openid',
          response_type: 'code',
          redirect_uri: 'http://localhost:3000/auth-code',
          logout_uri: 'http://localhost:3000/sign-out',
          state: 'STATE',
          grant_type: 'authorization_code',
        },
        client: {
          url: 'https://kcwppda4njcobly4vmdqyjsh3y.appsync-api.ap-southeast-1.amazonaws.com/graphql',
          region: 'ap-southeast-1',
          locale: 'my',
        },
      };

    case 'qamy':
      return {
        auth: {
          url: 'https://dashboard-dxi-qa-poc-my.auth.ap-southeast-1.amazoncognito.com',
          client_id: '1h8kkl0cfso5mrqcg1qvn7e4vp',
          client_secret: '',
          scope: 'openid',
          response_type: 'code',
          redirect_uri:
            'https://frontend.dashboard.qa-poc-my.dmc-dxi.com/auth-code',
          logout_uri:
            'https://frontend.dashboard.qa-poc-my.dmc-dxi.com/sign-out',
          state: 'STATE',
          grant_type: 'authorization_code',
        },
        client: {
          url: 'https://kcwppda4njcobly4vmdqyjsh3y.appsync-api.ap-southeast-1.amazonaws.com/graphql',
          region: 'ap-southeast-1',
          locale: 'my',
        },
      };

    case 'stgmy':
      return {
        auth: {
          url: 'https://dashboard-dxi-stg-poc-my.auth.ap-southeast-1.amazoncognito.com',
          client_id: '27d15n3aibt7d5utgrscrffc77',
          client_secret: '',
          scope: 'openid',
          response_type: 'code',
          redirect_uri:
            'https://frontend.dashboard.stg-poc-my.dmc-dxi.com/auth-code',
          logout_uri:
            'https://frontend.dashboard.stg-poc-my.dmc-dxi.com/sign-out',
          state: 'STATE',
          grant_type: 'authorization_code',
        },
        client: {
          url: 'https://pwda5jrig5gcpeokzj3vdvxcra.appsync-api.ap-southeast-1.amazonaws.com/graphql',
          region: 'ap-southeast-1',
          locale: 'my',
        },
      };

    default:
      throw Error(`Invalid env: ${BuildOpt.env as string}`);
  }
});
