import { Colors } from './Color';

type DeepReadonly<T> = Readonly<{
  [Key in keyof T]: DeepReadonly<T[Key]>;
}>;

type Theme = DeepReadonly<typeof unsafeTheme>;

const unsafeTheme = {
  header: {
    backgroundColor: Colors.mainColor1,
    borderColor: '#00bb00',
    fontColor: 'white',
  },
  footer: {
    borderColor: '#888888',
    backgroundColor: `white`,
  },
  sideBar: {
    borderColor: `black`,
  },
  toolbar: {
    backgroundColor: `#eeeeee`,
    goToTop: {
      backgroundColor: Colors.buttonColor2,
    },
    printDashboard: {
      backgroundColor: Colors.buttonColor3,
    },
    printSRO: {
      backgroundColor: Colors.buttonColor4,
    },
    search: {
      borderColor: `royalblue`,
    },
    refresh: {
      backgroundColor: Colors.buttonColor1,
    },
  },
  loading: {
    fontColor: Colors.mainColor1,
  },
  signIn: {
    backgroundColor: `#bbddbb`,
    pane: {
      backgroundColor: `#eeeeee`,
      button: {
        backgroundColor: Colors.buttonColor1,
      },
    },
  },
  home: {
    warn: {
      color: Colors.mainColor1,
    },
    button: {
      backgroundColor: Colors.buttonColor5,
    },
  },
  vehicleList: {
    titleBar: {
      beckgroundColor: `#ffdada`,
    },
    table: {
      border: `black`,
      header: {
        backgroundColor: `royalblue`,
        color: `white`,
        sort: {
          disabled: 0.3,
        },
      },
      row: {
        chipOwner: {
          backgroundColor: `tomato`,
          fontColor: `white`,
        },
        chipDriver: {
          backgroundColor: `gray`,
          fontColor: `white`,
        },
      },
    },
  },
  vehicleDetail: {
    title: {
      backgroundColor: `yellow`,
      fontColor: `red`,
      border: `black`,
    },
    map: {
      noLocation: {
        border: `#979797`,
        backgroundColor: `rgba(0, 0, 0, 0.05)`,
        color: `rgba(0, 0, 0, 0.35)`,
      },
    },
    table: {
      border: `black`,
      header: {
        backgroundColor: `royalblue`,
      },
    },
    chip: {
      owner: {
        backgroundColor: `tomato`,
        fontColor: `white`,
      },
      driver: {
        backgroundColor: `gray`,
        fontColor: `white`,
      },
      new: {
        backgroundColor: `red`,
        fontColor: `white`,
      },
    },
  },
};

export const Theme: Theme = unsafeTheme;
