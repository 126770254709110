import { DTCDetail } from '@auto/monaka-client/dist/dashboard';
import DownloadIcon from '@mui/icons-material/Download';
import { Box } from '@mui/system';
import React from 'react';
import { Chip } from '../../components/Chip';
import { IntlText } from '../../components/Intl';
import { getDtcMessage, getEcuMessage } from '../../i18n/DcuDtcConverter';
import { useIntl } from '../../i18n/Intl';
import { defaultLocale } from '../../i18n/Locale';
import { MessageId, MessageKeys } from '../../i18n/Message';
import { MUIStyle, Style } from '../../style/Style';
import { Theme } from '../../theme/Theme';
import { isToday } from '../../utils/Date';

const styles = Style({
  container: {},
  table: {
    display: 'grid',
    borderTop: `1px solid ${Theme.vehicleList.table.border}`,
    borderLeft: `1px solid ${Theme.vehicleList.table.border}`,

    '& .header-item': {
      padding: `4px`,
      wordWrap: 'break-word',
      wordBreak: 'break-all',
      borderBottom: `1px solid ${Theme.vehicleDetail.table.border}`,
      borderRight: `1px solid ${Theme.vehicleDetail.table.border}`,
      backgroundColor: Theme.vehicleDetail.table.header.backgroundColor,
      color: `white`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    '& .data-item': {
      position: `relative`,
      padding: `4px`,
      paddingTop: `8px`,
      wordWrap: 'break-word',
      wordBreak: 'break-all',
      borderBottom: `1px solid ${Theme.vehicleList.table.border}`,
      borderRight: `1px solid ${Theme.vehicleList.table.border}`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  headerDate: {
    gridRow: `1`,
    gridColumn: `1`,
  },
  headerECU: {
    gridRow: `1`,
    gridColumn: `2`,
  },
  headerDTCID: {
    gridRow: `1`,
    gridColumn: `3`,
  },
  headerDetail: {
    gridRow: `1`,
    gridColumn: `4`,
  },
  headerFFD: {
    gridRow: `1`,
    gridColumn: `5`,
  },

  newChip: {
    position: `absolute`,
    top: 0,
    left: 0,
    padding: `2px`,
    color: Theme.vehicleDetail.chip.new.fontColor,
    backgroundColor: Theme.vehicleDetail.chip.new.backgroundColor,
  },
  newChipText: {
    padding: 0,
    fontSize: `0.8em`,
  },
});

const headers: Array<[MUIStyle, MessageKeys]> = [
  [styles.headerDate, MessageId.vehicleDetailDTCHeaderDate],
  [styles.headerECU, MessageId.vehicleDetailDTCHeaderECU],
  [styles.headerDTCID, MessageId.vehicleDetailDTCHeaderDTCID],
  [styles.headerDetail, MessageId.vehicleDetailDTCHeaderDetail],
  [styles.headerFFD, MessageId.vehicleDetailDTCHeaderFFD],
];

export function DTCList({
  dtcDetailList,
}: Readonly<{
  dtcDetailList: DTCDetail[];
}>): React.ReactElement {
  const intl = useIntl(defaultLocale);
  return (
    <Box sx={styles.container}>
      <IntlText id={MessageId.vehicleDetailDTCTitle} />
      <Box sx={styles.table}>
        {headers.map(([style, msgId], key) => (
          <Box key={key} className='header-item' sx={style}>
            <IntlText id={msgId} />
          </Box>
        ))}

        {dtcDetailList.flatMap((d, i) =>
          [
            `${intl.formatDate(d.timestamp)} ${intl.formatTime(d.timestamp)}`,
            getEcuMessage(d.ecuCode, intl),
            d.dtcCode,
            getDtcMessage(d.ecuCode, d.dtcCode, intl),
            d.filePath !== '' ? (
              <a href={d.filePath} download>
                <DownloadIcon />
              </a>
            ) : (
              <Box>-</Box>
            ),
          ].map((t, j) => {
            return (
              <Box
                className='data-item'
                key={`${i}-${j}`}
                sx={{
                  gridRow: `${3 + i}`,
                  gridColumn: j + 1,
                }}
              >
                <Box>{t}</Box>

                {j === 0 && isToday(d.timestamp) ? (
                  <Chip
                    label={MessageId.vehicleDetailDTCNew}
                    sx={styles.newChip}
                    textSx={styles.newChipText}
                  />
                ) : null}
              </Box>
            );
          }),
        )}
      </Box>
    </Box>
  );
}
