type Env =
  | 'debug'
  | 'local-devjp'
  | 'local-devmy'
  | 'local-qamy'
  | 'devjp'
  | 'devmy'
  | 'qamy'
  | 'stgmy';

type BuildOptMode =
  | 'development' // npm run start (connect to localhost:3000
  | 'production' // npm run build (connect to a external server)
  | 'test';

export const BuildOpt = {
  env: (process.env.REACT_APP_BUILD_OPT as Env | undefined) ?? 'debug',
  mode: process.env.NODE_ENV as BuildOptMode,
};
