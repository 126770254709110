import { PrimitiveType } from 'intl-messageformat';
import React from 'react';
import * as intl from 'react-intl';
import { Lang, Locale } from './Locale';
import { MessageKeys, MessageTable } from './Message';
import { en } from './en';
import { ja } from './ja';

export interface Intl {
  readonly formatMessageAsNode: (
    descriptor: MessageDescriptor,
    values?: Record<string, PrimitiveType>,
  ) => React.ReactNode;
  readonly formatMessageAsString: (
    descriptor: MessageDescriptor,
    values?: Record<string, PrimitiveType>,
  ) => string;
  readonly formatDate: (date: Date, options?: intl.FormatDateOptions) => string;
  readonly formatDateToPars: (
    date: Date,
  ) => Array<{ type: string; value: string }>;
  readonly formatTime: (date: Date, options?: intl.FormatDateOptions) => string;
  readonly formatTimeToPars: (
    date: Date,
  ) => Array<{ type: string; value: string }>;
}

export interface MessageDescriptor {
  readonly id: MessageKeys;
}

export function getMessages(lang: Lang): MessageTable {
  switch (lang) {
    case 'en':
      return en;
    case 'ja':
      return ja;
    default:
      return en;
  }
}

function toReactNode(lines: string | string[]): React.ReactNode {
  const xs = typeof lines === 'string' ? [lines] : lines;
  return React.createElement(
    React.Fragment,
    null,
    ...xs.map((line, index) => {
      if (index === 0) return React.createElement(React.Fragment, null, line);
      else
        return React.createElement(
          React.Fragment,
          null,
          React.createElement('br'),
          line,
        );
    }),
  );
}

export function useIntl(locale: Locale): Intl {
  return React.useMemo(() => {
    const self = intl.createIntl(
      {
        locale: `${locale.lang}-${locale.region}`,
        messages: getMessages(locale.lang),
      },
      intl.createIntlCache(),
    );
    return {
      formatMessageAsNode: (
        descriptor: MessageDescriptor,
        values?: Record<string, PrimitiveType>,
      ) => {
        return toReactNode(
          self.formatMessage({ id: descriptor.id }, values).split('\n'),
        );
      },
      formatMessageAsString: (
        descriptor: MessageDescriptor,
        values?: Record<string, PrimitiveType>,
      ) => self.formatMessage({ id: descriptor.id }, values).replace('\n', ' '),
      formatDate: (date: Date, options?: intl.FormatDateOptions) =>
        self.formatDate(date, options),
      formatDateToPars: (date: Date) => self.formatDateToParts(date),
      formatTime: (date: Date, options?: intl.FormatDateOptions) =>
        self.formatTime(date, options),
      formatTimeToPars: (date: Date) => self.formatTimeToParts(date),
    };
  }, [locale]);
}
