import { VehicleProfile } from '@auto/monaka-client/dist/dashboard';
import PrintIcon from '@mui/icons-material/Print';
import ReplayIcon from '@mui/icons-material/Replay';
import SearchIcon from '@mui/icons-material/Search';
import { Box, MenuItem } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { VehicleDetail as VehicleDetailData } from '../backend/Api';
import { useIntl } from '../i18n/Intl';
import { defaultLocale } from '../i18n/Locale';
import { MessageId } from '../i18n/Message';
import { Style } from '../style/Style';
import { Theme } from '../theme/Theme';
import { downloadSamplePdf } from '../utils/pdf';
import { TextButton } from './Button';
import { IntlText } from './Intl';
import { Select } from './Select';
import { TextInput } from './TextInput';

type SearchType = 'Vin' | 'RegNo';

const SearchType: Readonly<Record<SearchType, SearchType>> = {
  Vin: 'Vin',
  RegNo: 'RegNo',
};

const styles = Style({
  container: {
    padding: `8px`,
    paddingLeft: `16px`,
    paddingRight: `16px`,
    backgroundColor: Theme.toolbar.backgroundColor,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    overflowX: 'auto',
  },
  left: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  right: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  asof: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  asofText: {
    fontWeight: `800`,
  },
  gap: {
    width: `8px`,
  },
  goToTop: {
    backgroundColor: Theme.toolbar.goToTop.backgroundColor,
  },
  printDashboard: {
    backgroundColor: Theme.toolbar.printDashboard.backgroundColor,
  },
  printSRO: {
    backgroundColor: Theme.toolbar.printSRO.backgroundColor,
  },
  search: {
    border: `1px solid ${Theme.toolbar.search.borderColor}`,
    width: `12em`,
  },
  searchButton: {
    backgroundColor: Theme.toolbar.search.borderColor,
    color: 'white',
  },
});

export function Toolbar({
  showGoToTop,
  showPrintDashboard,
  showPrintSRO,
  showSelectBox,
  showSearchBox,
  vehicleProfile,
  vehicleDetail,
}: Readonly<{
  showGoToTop?: boolean;
  showPrintDashboard?: boolean;
  showPrintSRO?: boolean;
  showSelectBox?: boolean;
  showSearchBox?: boolean;
  vehicleProfile?: VehicleProfile;
  vehicleDetail?: VehicleDetailData;
}>): React.ReactElement {
  const navigate = useNavigate();
  const intl = useIntl(defaultLocale);
  const [searchType, setSearchType] = React.useState<SearchType>('Vin');
  const [searchValue, setSearchValue] = React.useState('');
  const doSearch = React.useCallback(
    () =>
      navigate('/vehicle-detail', {
        state: {
          type: searchType,
          value: searchValue,
        },
      }),
    [searchType, searchValue, navigate],
  );
  const asof = new Date();

  const Text = Box;
  return (
    <Box sx={styles.container}>
      <Box sx={styles.left}>
        {showGoToTop === true ? (
          <>
            <TextButton
              title={intl.formatMessageAsString({
                id: MessageId.toolbarGoToTop,
              })}
              sx={styles.goToTop}
              onClick={() => navigate('/')}
            />

            <Box sx={styles.gap} />
          </>
        ) : null}

        {showPrintDashboard === true ? (
          <>
            <TextButton
              title={intl.formatMessageAsString({
                id: MessageId.toolbarPrintDashboard,
              })}
              sx={styles.printDashboard}
              leftIcon={<PrintIcon />}
              onClick={() => {
                window.print();
              }}
            />

            <Box sx={styles.gap} />
          </>
        ) : null}

        {showPrintSRO === true ? (
          <>
            {' '}
            <TextButton
              title={intl.formatMessageAsString({
                id: MessageId.toolbarPrintSRO,
              })}
              sx={styles.printSRO}
              leftIcon={<PrintIcon />}
              onClick={async () => {
                await downloadSamplePdf(vehicleProfile, vehicleDetail);
              }}
            />
            <Box sx={styles.gap} />
          </>
        ) : null}

        {showSelectBox === true ? (
          <>
            <Select
              defaultValue={SearchType.Vin}
              onChange={e => setSearchType(e.target.value as SearchType)}
            >
              <MenuItem value={SearchType.Vin}>
                <IntlText id={MessageId.toolbarSelectVin} />
              </MenuItem>
              <MenuItem value={SearchType.RegNo}>
                <IntlText id={MessageId.toolbarSelectReg} />
              </MenuItem>
            </Select>

            <Box sx={styles.gap} />
          </>
        ) : null}

        {showSearchBox === true ? (
          <TextInput
            rightIcon={<SearchIcon />}
            sx={styles.search}
            buttonSx={styles.searchButton}
            onChange={e => setSearchValue(e.target.value)}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                doSearch();
              }
            }}
            onClickButton={() => doSearch()}
          />
        ) : null}
      </Box>

      <Box sx={styles.gap} />

      <Box sx={styles.right}>
        <Box sx={styles.asof}>
          <Text sx={styles.asofText}>
            As of {intl.formatDate(asof)} {intl.formatTime(asof)}
          </Text>
        </Box>

        <Box sx={styles.gap} />

        <TextButton
          title={intl.formatMessageAsString({
            id: MessageId.toolbarRefreshTitle,
          })}
          leftIcon={<ReplayIcon />}
          onClick={() => window.location.reload()}
        />
      </Box>
    </Box>
  );
}
