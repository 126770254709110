import { MessageId, MessageKeys } from '../i18n/Message';
import {
  SortOff,
  SortOn,
  Sortable,
  VehicleListHeaderKeys,
} from '../screens/vehicle-list/VehicleList';

export type AppState = Readonly<{
  vehicleList: Readonly<{
    title: MessageKeys;
    sort: VehicleListSort;
  }>;
}>;

export type VehicleListSort = Readonly<{
  [key in VehicleListHeaderKeys]: Sortable;
}>;

export const initialAppState: AppState = {
  vehicleList: {
    title: MessageId.homeVehicleWarningTitle,
    sort: {
      vin: SortOn('Ascend'),
      regNo: SortOff('Ascend'),
      customerInfo: 'None',
      customerName: 'None',
      customerPhone: 'None',
      customerIc: SortOff('Ascend'),
      model: SortOff('Ascend'),
      warning: 'None',
      time: SortOff('Ascend'),
      paa: 'None',
    },
  },
};
