import * as Gen from '../generated/i18n/Types';
import { en } from './en';

export type MessageTable = Gen.MessagesType &
  Readonly<{
    headerTitle: string;
    footerCopyright: string;
    signOut: string;

    toolbarGoToTop: string;
    toolbarPrintDashboard: string;
    toolbarPrintSRO: string;
    toolbarSelectVin: string;
    toolbarSelectReg: string;
    toolbarRefreshTitle: string;

    signinPanelTitle: string;
    signinPlaceholderUserId: string;
    signinPlaceholderPassword: string;
    signinButtonTitle: string;

    homeSOSTitle: string;
    homeAirBagTitle: string;
    homeTheftAlertTitle: string;
    homeVehicleWarningTitle: string;
    homeButtonTitle: string;

    vehicleListTitle: string;
    vehicleListVin: string;
    vehicleListRegNo: string;
    vehicleListCustomerInfo: string;
    vehicleListCustomerName: string;
    vehicleListCustomerPhone: string;
    vehicleListCustomerIC: string;
    vehicleListModel: string;
    vehicleListWarning: string;
    vehicleListWarningUndefined: string;
    vehicleListTime: string;
    vehicleListPAA: string;
    vehicleListPAAMember: string;
    vehicleListPAANonMember: string;

    vehicleDetailBasicInfoTitle: string;
    vehicleDetailVin: string;
    vehicleDetailRegNo: string;
    vehicleDetailRegYear: string;
    vehicleDetailModel: string;
    vehicleDetailBodyColor: string;
    vehicleDetailPAA: string;
    vehicleDetailName: string;
    vehicleDetailCusId: string;
    vehicleDetailPhone: string;
    vehicleDetailChipOwner: string;
    vehicleDetailChipDriver: string;
    vehicleDetailTitle: string;
    vehicleDetailLocationTitle: string;
    vehicleDetailLocationAsOf: string;
    vehicleDetailVehicleStatusTitle: string;
    vehicleDetailVehicleStatusFuel: string;
    vehicleDetailVehicleStatusRange: string;
    vehicleDetailVehicleStatusDistance: string;
    vehicleDetailVehicleStatusAve: string;
    vehicleDetailWarningLampTitle: string;
    vehicleDetailWarningLampHeaderDate: string;
    vehicleDetailWarningLampHeaderTime: string;
    vehicleDetailWarningLampHeaderDetail: string;
    vehicleDetailDTCTitle: string;
    vehicleDetailDTCHeaderDate: string;
    vehicleDetailDTCHeaderECU: string;
    vehicleDetailDTCHeaderDTCID: string;
    vehicleDetailDTCHeaderDetail: string;
    vehicleDetailDTCHeaderFFD: string;
    vehicleDetailDTCNew: string;
    vehicleDetailEcuCodeUndefined: string;
    vehicleDetailDtcCodeUndefined: string;
  }>;

export type MessageKeys = keyof MessageTable;
export type MessageId = Readonly<{
  [Key in MessageKeys]: Key;
}>;

export const MessageId: MessageId = Object.keys(en).reduce((o, k) => {
  const _o = o as { [k: string]: string };
  _o[k] = k;
  return o;
}, {} as MessageId);
