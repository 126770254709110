import { Box } from '@mui/system';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AppFrame } from '../components/AppFrame';
import { ErrorBoundary } from '../components/ErrorBoundary';
import { Privileged } from '../components/Privileged';
import { useReducer } from '../reducer';
import { ErrorScreen } from '../screens/error/ErrorScreen';
import { Home } from '../screens/home/Home';
import { VehicleDetail } from '../screens/vehicle-detail/VehicleDetail';
import { VehicleList } from '../screens/vehicle-list/VehicleList';
import './App.css';
import { AppContext } from './AppContext';
import { initialAppState } from './AppState';

export function App(): React.ReactElement {
  const appContext = useReducer(initialAppState);
  return (
    <ErrorBoundary fallback={err => <ErrorScreen error={err} />}>
      <AppContext.Provider value={appContext}>
        <AppFrame>
          <Privileged>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/sign-out' element={<Box />} />
              <Route path='/vehicle-list' element={<VehicleList />} />
              <Route path='/vehicle-detail' element={<VehicleDetail />} />
            </Routes>
          </Privileged>
        </AppFrame>
      </AppContext.Provider>
    </ErrorBoundary>
  );
}
